import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CategoryV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__banner-area pt-20 go-top pb-40">
				<div className="container">
				
				<div className="row">
					<div className="col-lg-8 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= "https://source.unsplash.com/low-angle-photography-of-white-plane-on-sky-xQBc9qvW0Vk" >                        
						<div className="ltn__banner-info">
						<h3> Jetler </h3>
						<p> Satılık Uçak</p>
						<Link to="/jets"><mark> Devamını Oku</mark></Link>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= "https://source.unsplash.com/grayscale-photo-of-helicopter-ps9K6gMM1CE" >                        
						<div className="ltn__banner-info">
						<h3>Helikopterler</h3>
						<p> Satılık Uçak</p>
						<Link to="/helicopters"><mark> Devamını Oku</mark></Link>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= "https://source.unsplash.com/white-airplane-on-brown-field-under-gray-clouds-CUGLrvJQ3IA" >                        
						<div className="ltn__banner-info">
						<h3>Turboproplar</h3>
						<p> Satılık Uçak</p>
						<Link to="/turbo"><mark> Devamını Oku</mark></Link>
						</div>
					</div>
					</div>
					<div className="col-lg-8 col-md-6">
					<div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg= "https://source.unsplash.com/small-airplanes-on-a-runway-McuDoLZyJxw"  >                        
						<div className="ltn__banner-info">
						<h3>Pistonlar</h3>
						<p> Satılık Uçak</p>
						<Link to="/pistons"><mark> Devamını Oku</mark></Link>
						</div>
					</div>
					</div>
					
				</div>
				</div>
			</div>
			
        }
}

export default CategoryV3
import React, { Component } from 'react';

class ShopDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">


				<div className="row">
				
				<div className="col-lg-6 align-self-center ">
				<div className="about-us-info-wrap">
					<div className="  mb-20">
					<h1 className="section-title">
					2022 Bell 429</h1>
					<h5 className="">Aircraft For Sale, Bell, Helicopters</h5>
					<p>
					ÜRETİM: ÇAN<br/>
					MODEL: BELL 429<br/>
					YIL: 2022<br/>
					ÖZEL NUMARA: 57451<br/>
					KAYIT: PS-WMS<br/>
					TOPLAM SÜRE: 100 SAAT<br/>
					YER: BREZİLYA	</p>
					</div>
				
				</div>
				</div>
				<div className="col-lg-6 align-self-center pt-60">
				<div className="">
					<img src={publicUrl+"assets/img/helicopters/1.jpg"} alt="About Us Image" />
					
				</div>
				</div>
			</div>


				<div className="row">

					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">

						<h4 className="title-2">Galeri</h4>
						<div className="ltn__property-details-gallery mb-30">
						<div className="row">
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/jest/2.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/jest/7.jpg"} alt="Image" />
							</a>
							<a href={publicUrl+"assets/img/jest/3.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/jest/8.jpeg"} alt="Image" />
							</a>
							</div>
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/jest/4.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/jest/9.jpg"} alt="Image" />
							</a>
							</div>
						
						</div>
						</div>
					
					
						{/* APARTMENTS PLAN AREA START */}
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
							<div className="nav">
							<a data-bs-toggle="tab" href="#liton_tab_3_1">Detaylar</a>
							<a className="active show" data-bs-toggle="tab" href="#liton_tab_3_2">İçerik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_3" >Motor</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_4" >Aviyonik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_5" >Ek Ekipman</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_6" >Dış iç mekan</a>



							</div>
						</div>
						<div className="tab-content">
							<div className="tab-pane fade" id="liton_tab_3_1">
							<div className="ltn__apartments-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Uçak merkezli ve Brezilya'da kayıtlı – Dünya çapında teslimat yapabiliriz.<br/>
									Tüm bakım güncel – Üretim garantisi kapsamındadır.<br/>
									Textron tarafından Aralık/2022'de teslim edildi ve 7.500 lbs yükseltme kiti kuruldu.<br/>
									Executive iç mekan 7 yolcu için yapılandırılmıştır (Tek pilot).<br/>
									Ses Yalıtımı ile donatılmıştır, Yardımcı. Yakıt Tankı, Otopilot 4 Eksen, Bariyer Filtresi vb.<br/>
									Kaza ve olay geçmişi yoktur.<br/>
									Daima özel hangarda muhafaza edildi.</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade active show" id="liton_tab_3_2">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Yeni olduğundan sadece 100 saat.<br/>

7.500 lbs MTOW'a yükseltildi.
</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_3">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Motor Modeli: Pratt Whitney PW207D1<br/>

Yenilenmesinden bu yana yalnızca 100 Saat.<br/>

3.900 Saat Kalan.</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_4">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>4. Eksen Otomatik Pilot<br/>
									NAV/COM/GPS - GTN-750 Harita Görünümü Yükseltmesi<br/>
									NAV/COM/GPS - GTN-750 HTAWS Yükseltilmiş<br/>
									Trafik Kaçınma Sistemi - SkyTrax 605A (Avidyne)<br/>
									Hava Durumu Radarı Primus 660 Yeni Radome (MFD'de Görüntülenir)<br/>
									QuantiFLY Uçuş Verileri Monitörü (AA)<br/>
									Radar Altimetre Honeywell KRA 405B<br/>
									NAV/COM/GPS - GTN-750/650 (Garmin)
									Acil Durum Yer Belirleme Vericisi (ELT) - C406-NHM (Artex)
									Harita Görünümü</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>




							<div className="tab-pane fade" id="liton_tab_3_5">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Kokpit Ses Kaydedici/Uçuş Verileri Kaydedici<br/>
									Klima, Çift Evaporatörlü<br/>
									Hava Alma Havası Isıtıcı Ekipman<br/>
									Giriş Bariyeri Filtresi<br/>
									Kompresör Yıkama Kiti<br/>
									Tel Çarpması Koruma Kiti<br/>
									Otomatik Kapı Açıcıları<br/>
									Brüt Ağırlık Çekme Kiti<br/>
									Ses Yalıtımı<br/>
									İleri Yanıp Sönen Işık<br/>
									Yakıt Dolum Alanı Koruyucusu<br/>
									Yardımcı Yakıt Tankı Ekipmanı (39 US Gal) )<br/>
									Menteşeli ve Kayar Yolcu Kapısı Camları<br/>
									Mafsallı İniş Işığı<br/>
									200 Amp Marş Jeneratörü (Çift)<br/>
									Çift Kontrollü<br/>
									Havalandırma Havası Isıtıcısı<br/>
									Rotor Freni</p>
									</div>
								</div>
								</div>
							</div>
							</div>


							
							<div className="tab-pane fade" id="liton_tab_3_6">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Koltuklar - Kurumsal Yolcu 6 Yer - 4 Noktalı Emniyet Sistemli 18,5" Koltuklar<br/>
								2 Orta Koltukta Katlanır Masa -<br/>
								Mürettebat ve Yolcu Koltuğu Kılıfları, Koltuk Etekleri ve Panel Ek Parçaları için Hızlı Bağlantı Bağlantıları ve ICS Sabit Premium Deri Kapak<br/>
								Arka Bölme Kapatma Paneli Birinci Sınıf Deri<br/>
								Kaplama İç Paneller ve Suni Deri<br/>
								5VDC Çift USB Şarj Bağlantı Noktası (Pilot/Yardımcı Pilot) ve Yolcu Kabini ile Tavan Kaplaması<br/>
								Kokpit ve Kabinde LEMO kulaklık jaklarını içerecek şekilde mevcut ICS sisteminin yeniden kablolanması<br/>
								Kokpit ve Yolcu Kabin Tavan<br/>
								Kaplaması için Birinci Sınıf Döşeme - Kurumsal -w- LED Işıklar ve Ayarlanabilir AC Havalandırmaları ve Renk Koordinatlı Deri Döşeme</p>
									</div>
								</div>
								</div>
							</div>
							</div>
							</div>
								</div>
							</div>
							</div>
			
				</div>
				</div>
			</div>
        }
}

export default ShopDetails
import React, { Component } from 'react';

class ShopDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">


				<div className="row">
				
				<div className="col-lg-6 align-self-center ">
				<div className="about-us-info-wrap">
					<div className="  mb-20">
					<h1 className="section-title">
					2019 BEECHCRAFT BARON G58</h1>
					<h5 className="">Aircraft For Sale, Beechcraft, Pistons</h5>
					<p>
					ÜRETİCİ: BEECCHRAFT<br />
MODEL: BARON G58<br />
YIL: 2019<br />
SN: TH-2519<br />
TOPLAM SÜRE: 260 SAAT<br />
YER: BREZİLYA</p>
					</div>
				
				</div>
				</div>
				<div className="col-lg-6 align-self-center pt-60">
				<div className="">
					<img src={publicUrl+"assets/img/pistons/5.jpg"} alt="About Us Image" />
					
				</div>
				</div>
			</div>


				<div className="row">

					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">

						<h4 className="title-2">Galeri</h4>
						<div className="ltn__property-details-gallery mb-30">
						<div className="row">
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/pistons/6.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/pistons/6.jpg"} alt="Image" />
							</a>
							<a href={publicUrl+"assets/img/pistons/7.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/pistons/7.jpg"} alt="Image" />
							</a>
							</div>
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/pistons/8.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/pistons/8.jpg"} alt="Image" />
							</a>
							</div>
						
						</div>
						</div>
					
					
						{/* APARTMENTS PLAN AREA START */}
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
							<div className="nav">
							<a data-bs-toggle="tab" href="#liton_tab_3_1">Detaylar</a>
							<a className="active show" data-bs-toggle="tab" href="#liton_tab_3_2">İçerik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_3" >Motor</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_4" >Aviyonik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_5" >Ek ekipman</a>




							</div>
						</div>
						<div className="tab-content">
							<div className="tab-pane fade" id="liton_tab_3_1">
							<div className="ltn__apartments-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Uçak merkezli ve Brezilya'da kayıtlı – Dünya çapında teslimat yapabiliriz.<br />
Tüm bakım akımı.<br />
Executive iç mekan 5 yolcu için yapılandırılmıştır (tek pilot).<br />
Garmin G1000 NXi, ADS-B, Dijital Klima, Chartview, SVT vb. ile donatılmıştır.<br />
Yeni boya ve iç durum gibi.<br />
Kaza ve olay geçmişi yoktur ve daima hangarda muhafaza edilmiştir.</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade active show" id="liton_tab_3_2">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Yenilikten bu yana 260 saat<br/>

</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_3">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Yeni 1.640 Saatten Bu Yana 260 Saat<br/>
TSIO 550
										</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_4">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>GARMIN G1000 NXi Entegre Cam Panel Uçuş Kabini<br/>
Garmin GIA64 WAAS NAV/COM/GPS<br/>
Garmin GMA1360 Ses ve İnterkom Sistemi<br/>
Garmin GDU1050 Yüksek Çözünürlüklü Birincil Uçuş Ekranı<br/>
Garmin GDU1055 Yüksek Çözünürlüklü Çok Fonksiyonlu Ekran<br/>
Garmin GRS79 İrtifa Yön ve Referans Sistemi<br/>
ADS-B'li GTX345R Transponder Giriş/Çıkış Trafik<br/>
Garmin Güvenli Taksi ve FliteCharts ve Jeppesen Harita Görünümü<br/>
Garmin GWX75 Dijital Dikey Profil Renkli Radar<br/>
Garmin GFC700 Entegre 3 Eksenli Uçuş Kontrol Sistemi<br/>
Yalpalama Damperli Garmin Yükseklik ve Dikey Hız Seçici<br/>
Garmin GEA71B Motor Gösterge Sistemi (EIS)<br/>
Mürettebat Uyarı Sistemi (CAS) )<br/>
Bekleme Durumu / Hava Hızı / Altimetre Göstergesi<br/>
Garmin GDL69A XM Hava Durumu ve Müzik<br/>
PFD'de Garmin Sentetik Görüş<br/>
Garmin GTS800 Aktif Trafik Tavsiye Sistemi Garmin<br/>
TAWS-B</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>




							<div className="tab-pane fade" id="liton_tab_3_5">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Dijital Klima</p>
									</div>
								</div>
								</div>
							</div>
							</div>



							
							
							</div>
								</div>
							</div>
							</div>
			
				</div>
				</div>
			</div>
        }
}

export default ShopDetails
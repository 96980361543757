import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import PistonsPage1 from "./shop-components/pistons"

const PistonsPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Pistonlar" />
        <PistonsPage1 />
        <Footer />
    </div>
}

export default PistonsPage


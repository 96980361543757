import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Gallery extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__img-slider-area">
				<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<h1 className="section-title">Satılık Uçak</h1>
						<h5 className="">
Satışa sunduğumuz uçakları inceleyin</h5>

					</div>
					</div>
				</div>
				<div className="row ltn__image-slider-4-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
					
				
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						<a href="https://source.unsplash.com/low-angle-photography-of-white-plane-on-sky-xQBc9qvW0Vk"  data-rel="lightcase:myCollection" >
						<img src="https://source.unsplash.com/low-angle-photography-of-white-plane-on-sky-xQBc9qvW0Vk" alt="Image" />
						</a>
						<div className="ltn__img-slide-info">
						<div className="ltn__img-slide-info-brief gp-top">
							<h1><Link to="/portfolio-details">Jetler </Link></h1>
						</div>
						<div className="btn-wrapper go-top">
							<Link to="/portfolio-details" className="btn theme-btn-1 btn-effect-1 text-uppercase">Devamını Oku</Link>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						<a href="https://source.unsplash.com/grayscale-photo-of-helicopter-ps9K6gMM1CE" data-rel="lightcase:myCollection">
						<img src="https://source.unsplash.com/grayscale-photo-of-helicopter-ps9K6gMM1CE" alt="Image" />
						</a>
						<div className="ltn__img-slide-info">
						<div className="ltn__img-slide-info-brief gp-top">
							<h1><Link to="/portfolio-details">Helikopterler</Link></h1>
						</div>
						<div className="btn-wrapper go-top">
						<Link to="/portfolio-details" className="btn theme-btn-1 btn-effect-1 text-uppercase">Devamını Oku</Link>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						<a href="https://source.unsplash.com/white-airplane-on-brown-field-under-gray-clouds-CUGLrvJQ3IA" data-rel="lightcase:myCollection">
						<img src="https://source.unsplash.com/white-airplane-on-brown-field-under-gray-clouds-CUGLrvJQ3IA" alt="Image" />
						</a>
						<div className="ltn__img-slide-info">
						<div className="ltn__img-slide-info-brief gp-top">
							<h1><Link to="/portfolio-details"> Turboproplar</Link></h1>
						</div>
						<div className="btn-wrapper go-top">
						<Link to="/portfolio-details" className="btn theme-btn-1 btn-effect-1 text-uppercase">Devamını Oku</Link>
						</div>
						</div>
					</div>
					</div>
			
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						<a href="https://source.unsplash.com/a-small-plane-flying-through-a-blue-sky-sMy2DjboQ1Y" data-rel="lightcase:myCollection">
						<img src="https://source.unsplash.com/a-small-plane-flying-through-a-blue-sky-sMy2DjboQ1Y" alt="Image" />
						</a>
						<div className="ltn__img-slide-info">
						<div className="ltn__img-slide-info-brief gp-top">
							<h1><Link to="/portfolio-details">Jetler </Link></h1>
						</div>
						<div className="btn-wrapper go-top">
							<Link to="/portfolio-details" className="btn theme-btn-1 btn-effect-1 text-uppercase">Devamını Oku</Link>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						<a href="https://source.unsplash.com/small-airplanes-on-a-runway-McuDoLZyJxw"  data-rel="lightcase:myCollection">
						<img src="https://source.unsplash.com/small-airplanes-on-a-runway-McuDoLZyJxw" alt="Image" />
						</a>
						<div className="ltn__img-slide-info">
						<div className="ltn__img-slide-info-brief gp-top">
							<h1><Link to="/portfolio-details">Pistonlar </Link></h1>
						</div>
						<div className="btn-wrapper go-top">
						<Link to="/portfolio-details" className="btn theme-btn-1 btn-effect-1 text-uppercase">Devamını Oku</Link>
						</div>
						</div>
					</div>
					</div>

				</div>
				</div>
			</div>
        }
}

export default Gallery
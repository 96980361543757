import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PortfolioV1 from './section-components/portfolio-v1';
import Footer from './global-components/footer';

const Portfolio_V1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Medya ve Etkinlikler
" subheader="Geleneksel medya, sosyal medya ve etkinliklerdeki varlığımız" />
        <PortfolioV1 />
        <Footer />
    </div>
}

export default Portfolio_V1


import React, { Component } from 'react';

class ShopDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">


				<div className="row">
				
				<div className="col-lg-6 align-self-center ">
				<div className="about-us-info-wrap">
					<div className="  mb-20">
					<h1 className="section-title">
					2019 Piper M500</h1>
					<h5 className="">Aircraft For Sale, Piper, Turboprops</h5>
					<p>
					ÜRETİCİ: PIPER<br/>
MODELİ: M500<br/>
YIL: 2019<br/>
ÖZEL NUMARA: 4697670<br/>
KAYIT: PS-GTI<br/>
TOPLAM SÜRE: 200 SAAT<br/>
YER: BREZİLYA</p>
					</div>
				
				</div>
				</div>
				<div className="col-lg-6 align-self-center pt-60">
				<div className="">
					<img src={publicUrl+"assets/img/turbo/5.jpg"} alt="About Us Image" />
					
				</div>
				</div>
			</div>


				<div className="row">

					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">

						<h4 className="title-2">Galeri</h4>
						<div className="ltn__property-details-gallery mb-30">
						<div className="row">
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/turbo/6.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/turbo/6.jpg"} alt="Image" />
							</a>
							<a href={publicUrl+"assets/img/turbo/7.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/turbo/7.jpg"} alt="Image" />
							</a>
							</div>
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/turbo/8.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/turbo/8.jpg"} alt="Image" />
							</a>
							</div>
						
						</div>
						</div>
					
					
						{/* APARTMENTS PLAN AREA START */}
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
							<div className="nav">
							<a data-bs-toggle="tab" href="#liton_tab_3_1">Detaylar</a>
							<a className="active show" data-bs-toggle="tab" href="#liton_tab_3_2">İçerik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_3" >Motor</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_4" >Aviyonik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_6" >Ek ekipman</a>



							</div>
						</div>
						<div className="tab-content">
							<div className="tab-pane fade" id="liton_tab_3_1">
							<div className="ltn__apartments-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Uçak merkezli ve Brezilya'da kayıtlı – Dünya çapında teslimat yapabiliriz.<br/>
Tüm bakımları güncel – Motor boroskopu yeni yapılmıştır.<br/>
G1000NXi, ADS-B Çıkışı, Sentetik Görüş, FIKI, Whelen Boombeam, Surface Watch, WX500 Stormscope vb. ile donatılmıştır.<br/>
Yeni boya ve iç durum gibi.<br/>
Her zaman hangarda tutuldu.</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade active show" id="liton_tab_3_2">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Yenilikten bu yana 200 saat<br/>

</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_3">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>PW PT6A-42A<br/>
Yenir<br/>
3.400 Saatten bu yana 200 Saat kaldı</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_4">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Garmin G1000NXi Aviyonik Paketi<br/>
Çift Garmin GRS-79 AHRS<br/>
Çift Garmin GIA-64W<br/>
Garmin GFC-700 AFCS<br/>
Sınıf B Garmin GTS-825<br/>
Çift Garmin GTX-335R ve GTX 345R<br/>
Çift GA-35 WAAS Anteni<br/>
Aspen EFD-1000<br/>
Garmin GFC-700 Otomatik Pilot<br/>
Çift Garmin 10,4” PFD<br/>
Garmin GWX-70, 10 inç Anten<br/>
Tekli Garmin 12,4” MFD<br/>
Stormscope WX-500<br/>
GMC 710 AP Kontrol Cihazı ve Yaw Damper<br/>
Garmin GEA-71 Motor ve Gövde Arayüzü<br/>
Garmin GMA-350c<br/>
Garmin GAE-43 Yükseklik Kodlayıcı<br/>
Entegre Dijital Kabin Basınçlandırma<br/>
Garmin GDL-69A XM Uydu Hava Durumu<br/>
Çift Garmin GDU 1050<br/>
Çift Garmin GMU-44 Manyetometreler<br/>
Çift Garmin GDC-72 Hava Veri Bilgisayarları<br/>
Garmin GI-275</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>




							<div className="tab-pane fade" id="liton_tab_3_5">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Jeppsen Tablosunu Görüntüle<br/>
PiperAire Klima Sentetik<br/>
Görüş<br/>
Yüzey Saati<br/>
FIKI<br/>
Whelen Havacılık ve Uzay Boombeam</p>
									</div>
								</div>
								</div>
							</div>
							</div>


							
							
							</div>
								</div>
							</div>
							</div>
			
				</div>
				</div>
			</div>
        }
}

export default ShopDetails
import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Footer from './global-components/footer';

const ContactV1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="İletişim" subheader="Sorularınızı, yorumlarınızı veya önerilerinizi bırakın." />
        <ContactForm />
        <ContactInfo />

        <Footer />
    </div>
}

export default ContactV1


import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';

class Footer_v1 extends Component {

    componentDidMount() {

    	const $ = window.$;
    	
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);

         $('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

				$(window).scrollTop(0);

			setTimeout(function(){
			    	$(".quarter-overlay").fadeOut(300);
				}, 800);

        });


		$(document).on('click','.theme-btn-1 ', function(){ 
            $( 'div' ).removeClass( 'modal-backdrop' );
            $( 'div' ).removeClass( 'show' );
            $( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
        });
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
				<footer className="ltn__footer-area  ">
				  <div className="footer-top-area  section-bg-2 plr--5">
				    <div className="container-fluid">
				      <div className="row">
				        <div className="col-xl-4 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-about-widget">
				            <div className="footer-logo">
				              <div className="site-logo">
				                <img src={publicUrl+"assets/img/logo.png"} alt="Logo" />
				              </div>
				            </div>
				            <p>Global Aircraft Turkey olarak beş kıtaya yayılan uçakların alım satımı konusunda uzman hizmetler sunuyoruz. Uçak satın alma ve bakımına yönelik artan küresel talebi sorumlu, çevik ve entegre bir şekilde karşılamak için yaratıldı. Global Aircraft Turkey, uçağınızla ilgili konularda çalışır.
 </p>
				            <div className="footer-address">
				              <ul>
				              
				                <li>
				                
				                  <div className="footer-address-info">
				                    <p>Global Aircraft Turkey bir İlk Yatırım Havacılık İthalat İhracat Limeted Şirketi markasıdır.
</p>
				                  </div>
				                </li>
				              </ul>
				            </div>
				            <div className="ltn__social-media mt-20">
						    	<Social />
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">SATILIK UÇAK</h4>
				            <div className="footer-menu go-top">
				              <ul>
				                <li><Link to="/jets">Jetler</Link></li>
				                <li><Link to="/turbo">Turboproplar</Link></li>
				                <li><Link to="/pistons">Pistonlar </Link></li>
				                <li><Link to="/helicopters">Helikopterler</Link></li>
				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">SİTE</h4>
				            <div className="footer-menu go-top">
				              <ul>
				                <li><Link to="/">Anasayfa</Link></li>
				                <li><Link to="/about">Hakkımızda</Link></li>
				                <li><Link to="/contact">İletişim</Link></li>
				     
				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">Servisler</h4>
				            <div className="footer-menu go-top">
				              <ul>
				                <li><Link to="/services">Uçak Satışı</Link></li>
				                <li><Link to="/services">Uçak Satın Alma</Link></li>
				                <li><Link to="/services">İthalat | İhracat | Milletleştirme</Link></li>
				                <li><Link to="/services">Finansman</Link></li>


				              
				              </ul>
				            </div>
				          </div>
				        </div>
				      
				      </div>
				    </div>
				  </div>
				</footer>
        )
    }
}


export default Footer_v1
import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import BlogSlider from './global-components/blog-slider-v1';
import Footer from './global-components/footer';
import Gallery from './section-components/gallery-v1';

const Home_V1 = () => {
    return <div>
        <Navbar />
        <Banner />
        <BlogSlider customClass="section-subtitle-2 "/>
        <Gallery/>
        <Footer />
    </div>
}

export default Home_V1


import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServiceV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
				<div className="container">
			
				<div className="row  justify-content-center">
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
							<img src={publicUrl+"assets/img/icons/plane_7.png"} alt="Icon Image" />
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Uçak Satışı ve Alımı</Link></h3>
						<p>Dünya çapında 400'ün üzerinde uçağın başarıyla ticareti yapıldı.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
							<img src={publicUrl+"assets/img/icons/plane_7.png"} alt="Icon Image" />
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details"> Değerleme Hizmetleri</Link></h3>
						<p>Fiziksel unsurlar, bakım kayıtları ve pazar değişkenleri dikkate alınarak özelleştirilmiş değerleme.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
							<img src={publicUrl+"assets/img/icons/plane_7.png"} alt="Icon Image" />
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details"> Uçak Yönetimi </Link></h3>
						<p>Operasyonunuzu planlamak ve yönetmek, üretkenliğinizi en üst düzeye çıkarmak ve toplam maliyetleri azaltmak.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
							<img src={publicUrl+"assets/img/icons/plane_7.png"} alt="Icon Image" />
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Uçak Sigortası
</Link></h3>
						<p>Sahip olduğunuz veya yönettiğiniz uçak için özel ihtiyaçlarınıza uyacak geniş kapsamlı kapsam seçenekleri.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
							<img src={publicUrl+"assets/img/icons/plane_7.png"} alt="Icon Image" />
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">
İthalat ve İhracat süreci</Link></h3>
						<p>İthalat ve ihracat işlemlerinin hızlı ve doğru bir şekilde tamamlanmasını sağlamak için uygulanan deneyim ve durum tespiti.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
							<img src={publicUrl+"assets/img/icons/plane_7.png"} alt="Icon Image" />
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Kısmi mülkiyet</Link></h3>
						<p>Tüm mülk sahiplerinin faydalarını en üst düzeye çıkarmak için planlanan özelleştirilmiş kısmi sahiplik.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
							<img src={publicUrl+"assets/img/icons/plane_7.png"} alt="Icon Image" />
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Uçak Finansmanı</Link></h3>
						<p>Finansman ihtiyaçlarınız kapsamında uçak sahipliğini daha ulaşılabilir hale getirmek için tasarlanmış esnek çözümler.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
							<img src={publicUrl+"assets/img/icons/plane_7.png"} alt="Icon Image" />
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Fabrika kabul hizmetleri</Link></h3>
						<p>Konfor ve huzurla mümkün olan en iyi uçağı almanızı sağlamak için baştan sona tasarlanmış bir süreç.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
							<img src={publicUrl+"assets/img/icons/plane_7.png"} alt="Icon Image" />
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">
Feribot uçuş hizmetleri</Link></h3>
						<p>Uçağınızın teslimatını sağlamak için kalifiye personel, bol miktarda deneyim ve dünya çapında konumlanmış birçok bağlantı.</p>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ServiceV5
import React, { Component } from 'react';


class AboutV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
				<div className="container">
				<div className="row">
				
					<div className="col-lg-6 align-self-center">
					<div className="about-us-info-wrap">
						<div className=" ltn__section-title-2--- mb-20">
						<h1 className="section-title">Global Aircraft Turkey Hakkında</h1>
						<h5 className="">Küresel Uçak</h5>
						<p>
							Global Aircraft Turkey Corporation'ın misyonu, uçak alım ve satımı için danışmanlık hizmetlerinde mükemmelliği sağlamak, müşteriler için değeri en üst düzeye çıkarmak ve uçak ticareti işinde referans olmaktır. Vizyonumuz küresel havacılık pazarının önemli oyuncuları arasında yer almaktır. Temel değerlerimiz, müşterilerimizle güvene dayalı şeffaf bir ilişki kurarak en yüksek etik standartları yansıtır.
							Sadece tek bir pazarda değil Amerika, Avrupa, Orta Doğu ve Asya gibi büyük havacılık pazarlarında hizmet vermekteyiz.
							Fontainebleau Aviation ile ortaklaşa olarak müşterilerimize yakıt, uzun ve kısa vadeli hangar alanı, üçüncü taraf bakım ve kiralama hizmetleri gibi eksiksiz FBO hizmetleri sunuyoruz.
							
							</p>
						</div>
					
					
					</div>
					</div>
					<div className="col-lg-6 align-self-center">
					<div className="">
						<img src={publicUrl+"assets/img/others/8.jpg"} alt="About Us Image" />
						
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default AboutV4
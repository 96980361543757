import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BlogSlider extends Component {
  render() {
	
    let publicUrl = process.env.PUBLIC_URL+'/'
    let sectionClass = this.props.sectionClass ? this.props.sectionClass :''
    return (
      <div className={ "ltn__blog-area pt-115--- pb-70 go-top "+ sectionClass}>
		  <div className="container">
		   
		  <div className="row">
					<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center pt-80">
						<h1 className="section-title">Global Aircraft Turkey Corporation'a hoş geldiniz</h1>
						<h5 className="">
						Global Aircraft Turkey olarak beş kıtaya yayılan uçakların alım satımı konusunda uzman hizmetler sunuyoruz.
</h5>

					</div>
					</div>
				</div>
		
		    <div className="row  ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
		      {/* Blog Item */}
		   
		   {/* Blog Item */}
		   <div className="col-lg-12">
		        <div className="ltn__blog-item ltn__blog-item-3">
		          <div className="ltn__blog-img">
		            <Link to="/service"><img src="https://source.unsplash.com/white-airplane--FhoJYnw-cg" alt="#" /></Link>
		          </div>
		          <div className="ltn__blog-brief">
		          
		            <h3 className="ltn__blog-title"><Link to="/service">Yönetmek</Link></h3>
		            <div className="ltn__blog-meta-btn">
		              <div className="ltn__blog-meta">
		                <ul>
		                  <li className="ltn__blog-date">
						  Global Aircraft Turkey; Amerika, Avrupa, Orta Doğu ve Asya gibi büyük havacılık pazarlarında hizmet vermektedir. Yıllar içinde edindiği deneyimle bugün Latin Amerika, Kuzey Amerika, Avrupa, Asya ve Okyanusya'da geniş bir ortak ağı geliştirmiştir. İsmimizdeki küresel, hiçbir yere ulaşılamayacak anlamına gelir.		 </li>               </ul>
		              </div>
		           
		            </div>
					<div className="btn theme-btn-1 btn-effect-1 text-uppercase">
					<Link to="/service">Daha Fazla</Link>
		              </div>
		          </div>
		        </div>
		      </div>
			  <div className="col-lg-12">
		        <div className="ltn__blog-item ltn__blog-item-3">
		          <div className="ltn__blog-img">
		            <Link to="/service"><img src="https://source.unsplash.com/white-airplane-taking-off-during-daytime-_1REcmACJaQ" alt="#" /></Link>
		          </div>
		          <div className="ltn__blog-brief">
		          
		            <h3 className="ltn__blog-title"><Link to="/service">Uçak Satış/Satın Alma</Link></h3>
		            <div className="ltn__blog-meta-btn">
		              <div className="ltn__blog-meta">
		                <ul>
		                  <li className="ltn__blog-date">
							Global Aircraft Turkey, mevcut uçağınızın hem satın alınması hem de satışı için kapsamlı tavsiyeler sunar. Dünya çapında deneyime sahip ekibimiz, uçak ithalatı ve ihracatı sürecindeki tüm evrak işlerini denetler.</li>
		                </ul>
		              </div>
		           
		            </div>
					<div className="btn theme-btn-1 btn-effect-1 text-uppercase">
					<Link to="/service">Daha Fazla</Link>
		              </div>
		          </div>
		        </div>
		      </div>
		 
			    {/* Blog Item */}
				<div className="col-lg-12">
		        <div className="ltn__blog-item ltn__blog-item-3">
		          <div className="ltn__blog-img">
		            <Link to="/service"><img src="https://source.unsplash.com/business-present-with-tablet-computer-a-cropped-shot-of-consult-meeting-onfcZZr-5lY" alt="#" /></Link>
		          </div>
		          <div className="ltn__blog-brief">
		          
		            <h3 className="ltn__blog-title"><Link to="/service">Kısmi mülkiyet
</Link></h3>
		            <div className="ltn__blog-meta-btn">
		              <div className="ltn__blog-meta">
		                <ul>
		                  <li className="ltn__blog-date">
						  İster geniş özerkliğe sahip modern bir jet arıyor olsun, ister daha kısa yolculuklar için diğer uçak modellerini tercih ediyor olsun, farklı müşteri profillerine hitap edecek çeşitli boyutlardaki uçakların pazarlanması konusunda uzman deneyime sahibiz.</li>		                </ul>
		              </div>
		           
		            </div>
					<div className="btn theme-btn-1 btn-effect-1 text-uppercase">
					<Link to="/service">Daha Fazla</Link>
		              </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
    )
  }
}

export default BlogSlider;

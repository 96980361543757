import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import Jets1 from "./shop-components/jets"

const HelicoptersPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Jetler" />
        <Jets1 />
        <Footer />
    </div>
}

export default HelicoptersPage


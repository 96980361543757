import React, { Component } from 'react';

class ShopDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">


				<div className="row">
				
				<div className="col-lg-6 align-self-center ">
				<div className="about-us-info-wrap">
					<div className="  mb-20">
					<h1 className="section-title">
					2021 Piper M350</h1>
					<h5 className="">Aircraft For Sale, Piper, Pistons</h5>
					<p>
					ÜRETİCİ: PIPER<br />
MODELİ: M350<br />
YIL: 2021<br />
ÖZEL NUMARA: 4636787<br />
KAYIT: PS-ABS<br />
TOPLAM SÜRE: 151 SAAT<br />
YER: BREZİLYA</p>
					</div>
				
				</div>
				</div>
				<div className="col-lg-6 align-self-center pt-60">
				<div className="">
					<img src={publicUrl+"assets/img/pistons/1.jpg"} alt="About Us Image" />
					
				</div>
				</div>
			</div>


				<div className="row">

					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">

						<h4 className="title-2">Galeri</h4>
						<div className="ltn__property-details-gallery mb-30">
						<div className="row">
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/pistons/2.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/pistons/2.jpg"} alt="Image" />
							</a>
							<a href={publicUrl+"assets/img/pistons/3.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/pistons/3.jpg"} alt="Image" />
							</a>
							</div>
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/pistons/4.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/pistons/4.jpg"} alt="Image" />
							</a>
							</div>
						
						</div>
						</div>
					
					
						{/* APARTMENTS PLAN AREA START */}
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
							<div className="nav">
							<a data-bs-toggle="tab" href="#liton_tab_3_1">Detaylar</a>
							<a className="active show" data-bs-toggle="tab" href="#liton_tab_3_2">İçerik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_3" >Motor</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_4" >Aviyonik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_6" >Ek ekipman</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_6" >Dış iç mekan</a>




							</div>
						</div>
						<div className="tab-content">
							<div className="tab-pane fade" id="liton_tab_3_1">
							<div className="ltn__apartments-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>UKESİR MÜLKİYETİ<br/>
								1/3'ÜN 2 KESİRLİ SATIŞ<br/>
								Brezilya merkezli ve kayıtlı uçak – Dünya çapında teslimat yapabiliriz.<br/>
								Tüm bakım güncelliği – 1.849 Kalan Saat.<br/>
								5 yolcu için executive iç mekan (tek pilot).<br/>
								Aviyonik Garmin G1000NXi, AP GFC700, Hız frenleri, klima vb. ile donatılmıştır.<br/>
								Yeni iç mekan ve boya durumu gibi.<br/>
								Kaza ve olay geçmişi yoktur.<br/>
								Daima özel hangarda muhafaza edildi.</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade active show" id="liton_tab_3_2">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Yeni'den bu yana 151 saat<br/>

</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_3">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Lycoming TIO-540-AE2A<br/>
										Yeni 151 saatin üzerinden<br/>
										1.849 saat kaldı
										</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_4">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Garmin G1000NXi PFD/MFD<br/>
									Garmin GFC700 Otopilot<br/>
									Garmin GMA 350 Ses Paneli<br/>
									Yaw Damper<br/>
									Garmin GTX33 Transponder<br/>
									VHF Radyolar NAV/COMM</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>




							<div className="tab-pane fade" id="liton_tab_3_5">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Hız Frenleri<br/>
									Klima Okuma Işıkları<br/>
									Tablolar<br/>
									ELT<br/>
									LED<br/>
									Işıklar</p>
									</div>
								</div>
								</div>
							</div>
							</div>


							<div className="tab-pane fade" id="liton_tab_3_6">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>5 yolcu + 1 Pilot için executive iç mekan</p>
									</div>
								</div>
								</div>
							</div>
							</div>

							
							
							</div>
								</div>
							</div>
							</div>
			
				</div>
				</div>
			</div>
        }
}

export default ShopDetails
import React, { Component } from 'react';

class ShopDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">


				<div className="row">
				
				<div className="col-lg-6 align-self-center ">
				<div className="about-us-info-wrap">
					<div className="  mb-20">
					<h1 className="section-title">
					2007 Eurocopter AS350B2</h1>
					<h5 className="">2007 Eurocopter AS350B2</h5>
					<p>
					YIL: 2007<br/>
ÜRETİM: EUROCOPTER<br/>
MODEL: AS350B2<br/>
TOPLAM SÜRE: YENİDEN BERİ 1.980 SAAT<br/>
YER: BREZİLYA<br/>
ÖZEL NUMARA: 4156</p>
					</div>
				
				</div>
				</div>
				<div className="col-lg-6 align-self-center pt-60">
				<div className="">
					<img src={publicUrl+"assets/img/helicopters/5.jpg"} alt="About Us Image" />
					
				</div>
				</div>
			</div>


				<div className="row">

					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">

						<h4 className="title-2">Galeri</h4>
						<div className="ltn__property-details-gallery mb-30">
						<div className="row">
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/helicopters/6.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/helicopters/6.jpg"} alt="Image" />
							</a>
							<a href={publicUrl+"assets/img/helicopters/7.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/helicopters/7.jpg"} alt="Image" />
							</a>
							</div>
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/helicopters/8.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/helicopters/8.jpg"} alt="Image" />
							</a>
							</div>
						
						</div>
						</div>
					
					
						{/* APARTMENTS PLAN AREA START */}
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
							<div className="nav">
							<a data-bs-toggle="tab" href="#liton_tab_3_1">Detaylar</a>
							<a className="active show" data-bs-toggle="tab" href="#liton_tab_3_2">İçerik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_3" >Motor</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_4" >Aviyonik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_6" >Dış iç mekan</a>



							</div>
						</div>
						<div className="tab-content">
							<div className="tab-pane fade" id="liton_tab_3_1">
							<div className="ltn__apartments-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Uçak merkezli ve Brezilya'da kayıtlı – Dünya çapında teslimat yapabiliriz.<br/>
									Tüm bakım akımı – 144 ay muayenesi yapıldı.<br/>
									15 Yıllık motor muayenesi 2023'te tamamlandı.<br/>
									Executive iç mekan seçeneği: 4 koltuklu (standart) veya kol dayanaklı 3 koltuklu.<br/>
									5 yolcu + 1 Pilot veya 4 yolcu + 1 Pilot konfigürasyonu.<br/>
									Kaza ve olay geçmişi yoktur ve daima hangarda muhafaza edilmiştir.<br/>
									Klima, Kum Filtresi, Garmin GNS 430, Hareketli Harita vb. ile donatılmıştır.<br/>
									Yeni boya durumu ve iç kısmı çok bakımlı gibi.</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade active show" id="liton_tab_3_2">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>UÇAK GRUBU - TTSN: 1.980 Saat<br/>

</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_3">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>MOTOR (ARRIEL 1D1) - TTSN: 1.980 Saat</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_4">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Garmin GNS 430<br/>
Bendix King Hareketli Harita Bendix King VHF İletişim<br/>
Radyo<br/>
Garmin GMA 340 Ses Paneli<br/>
Garmin GTX 327 Dijital Transponder<br/>
ELT 406<br/>
Klima<br/>
Kum Filtresi<br/>
Genişletilmiş Basamaklı Yüksek Kızak<br/>
Kargo Ağı<br/>
Kulaklıklar<br/>
Kol Dayanağı Opsiyonel<br/>
PVC Kapak zemini<br/>
CD Çalar<br/>
Silecek (Pilot Tarafı)</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>




							<div className="tab-pane fade" id="liton_tab_3_6">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Executive iç mekan seçeneği: 4 kişilik koltuk (standart) veya 3 kişilik kol dayanaklı koltuk.<br/>
5 yolcu + 1 Pilot veya 4 yolcu + 1 Pilot konfigürasyonu.<br/>
Yeni boya durumu ve iç kısmı çok bakımlı gibi</p>
									</div>
								</div>
								</div>
							</div>
							</div>


							
							
							</div>
								</div>
							</div>
							</div>
			
				</div>
				</div>
			</div>
        }
}

export default ShopDetails
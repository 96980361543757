import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import CategoryV2 from './section-components/category-v3';

const Cart_V1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Satılık Uçaklar" />
        <CategoryV2 />
        <Footer />
    </div>
}

export default Cart_V1


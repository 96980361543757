import React, { Component } from 'react';

class ShopDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">


				<div className="row">
				
				<div className="col-lg-6 align-self-center ">
				<div className="about-us-info-wrap">
					<div className="  mb-20">
					
					<h5 className="">Aircraft For Sale, Pilatus, Turboprops</h5>
					<p>
					ÜRETİCİ: PILATUS<br/>
MODEL: PC-12 NGX<br/>
YIL: 2023<br/>
ÖZEL NUMARA: 2287<br/>
TOPLAM SÜRE: 80 SAAT<br/>
YER: ABD</p>
					</div>
				
				</div>
				</div>
				<div className="col-lg-6 align-self-center pt-60">
				<div className="">
					<img src={publicUrl+"assets/img/turbo/1.jpg"} alt="About Us Image" />
					
				</div>
				</div>
			</div>


				<div className="row">

					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">

						<h4 className="title-2">Galeri</h4>
						<div className="ltn__property-details-gallery mb-30">
						<div className="row">
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/turbo/2.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/turbo/2.jpg"} alt="Image" />
							</a>
							<a href={publicUrl+"assets/img/turbo/3.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/turbo/3.jpg"} alt="Image" />
							</a>
							</div>
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/turbo/4.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/turbo/4.jpg"} alt="Image" />
							</a>
							</div>
						
						</div>
						</div>
					
					
						{/* APARTMENTS PLAN AREA START */}
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
							<div className="nav">
							<a data-bs-toggle="tab" href="#liton_tab_3_1">Detaylar</a>
							<a className="active show" data-bs-toggle="tab" href="#liton_tab_3_2">İçerik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_3" >Motor</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_4" >Aviyonik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_6" >Ek ekipman</a>



							</div>
						</div>
						<div className="tab-content">
							<div className="tab-pane fade" id="liton_tab_3_1">
							<div className="ltn__apartments-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>ABD merkezli ve kayıtlı. Dünya çapında teslimat yapabiliriz.<br/>
Yeni Gibi ve Fabrikadan Tamamen Opsiyonlu.<br/>
İlk 400 saat ön ödemeli olarak ESP Gold'a kayıtlıdır<br/>
İkinci GPS, Stormscope WX500, RAAS, Otomatik Gaz, XM Hava Durumu, Çift Haritalar, KMA29A, TCAS II, RDR2060 Hava Durumu Radarı Yükseltmesi vb. ile donatılmıştır.<br/>
Fabrikadan Tam Garanti.<br/>
Hasar veya Olay Geçmişi Yok.</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade active show" id="liton_tab_3_2">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Yeni olmasından bu yana sadece 80 saat<br/>

</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_3">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>PW PT6E-67XPr<br/>

Yenir<br/>

5 Kanatlı Pervanenin üzerinden yalnızca 80 Saat geçti</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_4">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>2D Havaalanı Haritaları + ADS-B IN (SURF)<br/>
Yardımcı Pilot PFD, Ses / İşaret Paneli ile<br/>
İkinci GPS<br/>
İkinci Mod S Transponder (ADS-B Çıkışı)<br/>
KMA-29A Ses Paneli Yükseltmesi<br/>
Stormscope® (WX500)<br/>
SmartRunway ve SmartLanding (RAAS)<br/>
VHF AFIS<br/>
Uydu Grafiği Hava Durumu S-XM Temel<br/>
Uydu Grafiği Hava Durumu S-XM Gelişmiş<br/>
RDR 2060 Hava Durumu Radarı Yükseltmesi ile Veri Bağlantısı</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>




							<div className="tab-pane fade" id="liton_tab_3_5">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>CAS Bağlantılı<br/>
Iridium® Anteni ve Bağlantı Noktası ile Elektronik Kontrol Listesi<br/>
Otomatik Gaz Kelebeği Darbeli<br/>
Tanıma Işıkları<br/>
TCAS II<br/>
Klima Sistemi<br/>
Can Yeleği<br/>
2 Isıtmasız Ni-Cad Pil<br/>
Çift iPad mini yuvası (iPad dahil değildir)<br/>
Kokpitte USB Şarj Bağlantı Noktaları<br/>
Kokpit Trafik Bilgilerinin Görüntülenmesi<br/>
Kablosuz Bağlantılı Uçuş Güvertesi<br/>
Honeywell İkili Haritalar</p>
									</div>
								</div>
								</div>
							</div>
							</div>


							
							
							</div>
								</div>
							</div>
							</div>
			
				</div>
				</div>
			</div>
        }
}

export default ShopDetails
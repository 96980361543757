import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PortfolioV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+''

    return <div className="ltn__gallery-area mb-120">
				<div className="container">
					{/* (ltn__gallery-info-hide) Class for 'ltn__gallery-item-info' not showing */}
					<div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
						<div className="ltn__gallery-sizer col-1" />
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/gallery/1.jpg"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/gallery/1.jpg"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details">Turboprops ve Jets  </Link></h4>
							</div>
						</div>
						</div>


						<div className="ltn__gallery-sizer col-1" />
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/gallery/1.jpg"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/gallery/2.jpg"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details">Embraer Phenom 300 vs Cessna Citation CJ4 </Link></h4>
							</div>
						</div>
						</div>


					
							{/* gallery-item */}
							<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/gallery/4.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/gallery/4.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> </Link></h4>
							</div>
						</div>
						</div>




						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/gallery/13.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/gallery/13.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> </Link></h4>
							</div>
						</div>
						</div>






						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/gallery/9.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/gallery/9.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> </Link></h4>
							</div>
						</div>
						</div>


					</div>
				
					<div className="btn-wrapper text-center">
						<Link to="#" className="btn btn-transparent btn-effect-3 btn-border">Daha Fazla +</Link>
					</div>
					</div>
			</div>
        }
}

export default PortfolioV1
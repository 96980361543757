import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class Sidebar extends Component {
  render() {
    return (
		<div className="col-lg-4  mb-100">
			<aside className="sidebar ltn__shop-sidebar">
			{/* Advance Information widget */}
			<div className="widget ltn__menu-widget">
			<Link to="/jets">
	<h4 className="ltn__widget-title">Jetler</h4></Link>
				<ul>
				<li>
					<label className="checkbox-item">Bombardier
					</label>
				</li>
				<li>
					<label className="checkbox-item">Cessna
					</label>
				</li>
				<li>
					<label className="checkbox-item">Dassault
					</label>
				</li>
				<li>
					<label className="checkbox-item">Embraer
					</label>
				</li>
				<li>
					<label className="checkbox-item">Gulf Stream 
					</label>
				</li>
				<li >
					<label className="checkbox-item">Hawker
					</label>
				</li>
				</ul>
				<hr />
				<Link to="/turbo">
	<h4 className="ltn__widget-title">Turboproplar</h4></Link>
				<ul>
				<li>
					<label className="checkbox-item">Pilatus
					</label>
				</li>
				<li>
					<label className="checkbox-item">Piper
					</label>
				</li>
			
				</ul>
				<hr />
				<Link to="/pistons">
	<h4 className="ltn__widget-title">Pistonlar</h4></Link>
				<ul>
				<li>
					<label className="checkbox-item">Beechcraft
					</label>
				</li>
				<li>
					<label className="checkbox-item">Cessna
					</label>
				</li>
				<li>
					<label className="checkbox-item">Mooney
					</label>
				</li>
				<li>
					<label className="checkbox-item">Piper
					</label>
				</li>
				<li>
					<label className="checkbox-item">Stinson
					</label>
				</li>
				</ul>
				<hr />
				<Link to="/helicopters">
	<h4 className="ltn__widget-title">Helikopterler</h4></Link>
				<ul>
				<li>
					<label className="checkbox-item">Agusta
					</label>
				</li>
				<li>
					<label className="checkbox-item">Bell
					</label>
				</li>
				<li>
					<label className="checkbox-item">Eurocopter
					</label>
				</li>
				<li>
					<label className="checkbox-item">Robinson
					</label>
				</li>
				<li>
					<label className="checkbox-item">Sikorsky
					</label>
				</li>
				</ul>
			</div>
		
			
			</aside>
		</div>
    )
  }
}

export default Sidebar;

import React, { Component } from 'react';


class ContactInfo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
				<div className="row">
					<div className="col-lg-6">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/plane_6.png"} alt="Icon Image" />
						</div>
						<h5>Global Aircraft Turkey</h5>
						<p>
						Merkez:<br/>
						Maidan<br/>
						Mustafa Kemal Mh. 2118. Cd. Maidan İş Merkezi<br/>
						4 C Blok No:140 Çankaya/ANKARA<br/>
						e-Mail: <a href="mailto:sales@globalaircrafts.com.tr">sales@globalaircrafts.com.tr</a>
<h5></h5>

</p>
					</div>
					</div>
					{/* <div className="col-lg-6">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/plane_6.png"} alt="Icon Image" />
						</div>
						<h5>BREZİLYA</h5>
						<p>

Salvador – +55 71 4062-9855<br/>
São Paulo – +55 11 3280-0040<br/>
Rio de Janeiro – +55 21 4063-7308<br/>
Brasília – +55 61 4042-1455<br/>
Curitiba – +55 41 4042-7430<br/>
Belo Horizonte – +55 31 4042 -8764<br/>
vendas@globalaircrafts.com<br/><br/>

<h5>HANGARLAR</h5><br/>
São Paulo: Jundiaí Havaalanı (SBJD)<br/>
Hangar Delta Air<br/>

Sorocaba, SP: Sorocaba Havaalanı (SOD)<br/>
Hangar Jetcare Havacılık<br/>

Salvador, BA: Salvador Uluslararası Havaalanı (SSA)<br/>
Hangar Vem Aviação<br/>

Navegantes, Güney Carolina: Navegantes Havaalanı (NVT)<br/>
Hangarı Polyfly Aviação</p>
					</div>
					</div> */}
					
				</div>
				</div>
			</div>
        }
}

export default ContactInfo
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TeamV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__team-area pt-110--- pb-90">
				<div className="container">
				<div className="row justify-content-center go-top">
					<div className="col-lg-4 col-sm-6">
					<div className=" ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/4.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4 className='ltn__secondary-color'><Link to="/team-details">Vinicius Pires – Başkan</Link></h4>
						<h6 className="">Havacılık endüstrisinde deneyime sahip doğuştan bir girişimci, müzakereleri hızlandırma biçimiyle öne çıkıyor. Global Aircraft Turkey Corp.'un önünde, yıllar içinde edindiği bilgi birikimini bir Uçağın satın alınması veya satılması için kusursuz bir süreç oluşturmak için kullanıyor. Geniş ağı, piyasada açıkça bulunmayan fırsatları bulmanıza olanak tanır ve her zaman yeni zorluklara hazırdır.</h6>
					
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 ">
					<div className=" ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/3.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4 className='ltn__secondary-color'><Link to="/team-details">Jonas López – Başkan Yardımcısı / Brezilya Şubesi</Link></h4>
						<h6 className="">Genel havacılık ve ticari farkındalık konusunda 25 yılı aşkın tecrübesiyle bir havacılık eğitim kurumunda Direktör olarak çalışmış; girişimci olarak ve hava taksi şirketleri için uçak ticareti ve filo oluşturma konularında faaliyet göstermektedir. Halen Global Aircraft Turkey ile olan faaliyetlerinin yanı sıra, Evektor Aircraft ve Stemme AG üreticilerinin Brezilya'daki temsilcisidir .</h6>
					
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6">
					<div className=" ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/2.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4 className='ltn__secondary-color'><Link to="/team-details">				
							</Link>Felipe Cortelezzi – Operasyon Direktörü</h4>
													<h6 className="">Medya Grubu alanında 19 yıllık deneyime sahip çapraz fonksiyonel profesyonel.
							Güçlü liderlik, operasyonel, ticari, teknik ve problem çözme becerileri.
							Ekip oluşturma, yürütme ve gözetim konusunda uzmandır. İş operasyonları, strateji, planlama ve gelir yaratma konularında rehberlik sağlar.
							Olumlu dönüşümler için paydaşları dahil etme ve etkileme becerisine sahip.

							</h6>
					
						</div>
					</div>
					</div>
				
					<div className="col-lg-4 col-sm-6 mt-20">
					<div className=" ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/1.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4 className='ltn__secondary-color'><Link to="/team-details">Marcos Caput – Brezilya Satış Direktörü
					</Link></h4>
						<h6 className="">2008 yılından bu yana Executive Aviation'da çalışmaktadır ve Air Taxi, FBO ve Executive Handling operasyonlarında deneyime sahiptir. 2014'ten beri Global Aircraft Turkey'dedir. 20.000'den fazla fotoğrafıyla havacılık sektörünün tanınmış bir Fotoğrafçısıdır. FTC Koleji'nde Havacılık Bilimi okudu. Aynı zamanda düzinelerce müşteriyle ticari ve idari uçaklar için boya düzenlerinin geliştirilmesinde de çalışmaktadır. Boş zamanlarında hobi olarak uçak pilotluğu yapıyor.</h6>
					
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 mt-20">
					<div className="ltn__team-item-3---">
						<div className="team-img">
						<img src={publicUrl+"assets/img/team/6.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4 className='ltn__secondary-color'><Link to="/team-details">Jaiane Costa – Piyasa Analisti
					</Link></h4>
						<h6 className="">Şu anda Miami'de yaşayan Jaiane Costa, yeni iş ve müşteri ilişkileri alanında geniş deneyime sahip olup, pazardaki en iyi fırsatları bulmak ve şirketin müşterileri için en iyi anlaşmaları yapmasına yardımcı olmak amacıyla 2022 yılında Global Aircraft Turkey ekibine katıldı.

</h6>
					
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 mt-20">
					<div className="ltn__team-item-3---">
						<div className="team-img ">
						<img src={publicUrl+"assets/img/team/5.jpg"} alt="Image" />
						</div>
						<div className="team-info">
						<h4 className='ltn__secondary-color'><Link to="/team-details">Sarah Ferreira – Pazar Araştırmacısı
					</Link></h4>
						<h6 className="">Sarah, Satış ve pazar araştırmalarında geniş deneyime sahip bir geçmişe sahiptir. Sarah, Florida Uluslararası Üniversitesi'nden Ticari Satış ve Girişimcilik alanında mezun oldu. Sarah, Portekizce, İngilizce ve İspanyolca dillerini akıcı bir şekilde üç dilde konuşabiliyor. 2022'den beri Global Aircraft Turkey'de çalışıyor.</h6>
					
						</div>
					</div>
					</div>
				
				
					
				
				</div>
				</div>
			</div>
        }
}

export default TeamV2
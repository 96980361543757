import React, { Component } from 'react';

class ShopDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">


				<div className="row">
				
				<div className="col-lg-6 align-self-center ">
				<div className="about-us-info-wrap">
					<div className="  mb-20">
					<h1 className="section-title">
               2021 Cessna Citation M2</h1>
					<h5 className="">Aircraft For Sale, Cessna, Jets</h5>
					<p>
					MANUFACTURER: CESSNA<br/>
					MODEL: CITATION M2<br/>
					YEAR: 2021<br/>
					TOTAL TIME: 410 HOURS<br/>
					LOCATION: BRAZIL
						</p>
					</div>
				
				</div>
				</div>
				<div className="col-lg-6 align-self-center pt-60">
				<div className="">
					<img src={publicUrl+"assets/img/others/20.jpg"} alt="About Us Image" />
					
				</div>
				</div>
			</div>


				<div className="row">

					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">

						<h4 className="title-2">Galeri</h4>
						<div className="ltn__property-details-gallery mb-30">
						<div className="row">
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/jest/3.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/jest/3.jpg"} alt="Image" />
							</a>
							<a href={publicUrl+"assets/img/jest/2.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/jest/2.jpg"} alt="Image" />
							</a>
							</div>
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/jest/3.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/jest/5.jpg"} alt="Image" />
							</a>
							</div>
						
						</div>
						</div>
					
					
						{/* APARTMENTS PLAN AREA START */}
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
							<div className="nav">
							<a data-bs-toggle="tab" href="#liton_tab_3_1">Detaylar</a>
							<a className="active show" data-bs-toggle="tab" href="#liton_tab_3_2">İçerik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_3" >Motor</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_4" >Avionics</a>
							</div>
						</div>
						<div className="tab-content">
							<div className="tab-pane fade" id="liton_tab_3_1">
							<div className="ltn__apartments-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Uçak merkezli ve Brezilya'da kayıtlı – Dünya çapında teslimat yapabiliriz.<br/>
Tüm bakımları Textron Servis Merkezi tarafından yapılmaktadır.<br/>
Program Yok.<br/>
Executive iç mekan 7 yolcu için yapılandırılmıştır (Tek Pilot).<br/>
Kemerli tuvalet ile donatılmıştır.<br/>
Boyası ve iç kısmı yeni gibi.<br/>
Kaza ve olay geçmişi yoktur ve daima hangarda muhafaza edilmiştir.</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade active show" id="liton_tab_3_2">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Yeniliğinden bu yana 410 saat.
</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_3">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Yeniliğinden bu yana 410 saat.<br/>

									No Program.</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_4">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Garmin G3000 Aviyonik Paketi:<br/>
									AHRS: Çift Garmin katı hal dijital<br/>
									Otopilot: Garmin GFC-700<br/>
									İletişim Radyoları: Çift Garmin GIA-63W<br/>
									CVR: Hükümler<br/>
									DME: Collins DME-4000<br/>
									EFIS: Garmin 3 tüplü 14 inç<br/>
									Uçuş Direktörü: Garmin GFC- 700<br/>
									GPS: Çift Garmin GIA-63W<br/>
									Navigasyon Telsizleri: Çift Garmin GIA-63W<br/>
									Radar Altimetre: Collins ALT-4000<br/>
									TAWS: Garmin Sınıf A<br/>
									TCAS: Garmin TCAS-I<br/>
									Transponder: Çeşitlilik ile Çift Garmin Modu Yazılımı<br/>
									Hava Durumu Radarı: Garmin GWX-70 katı hal</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>
							</div>
								</div>
							</div>
							</div>
			
				</div>
				</div>
			</div>
        }
}

export default ShopDetails
import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import ServiceV5 from './section-components/service-v1';

const Service_V1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Hizmetler" subheader="Yerel Varlık. Global erişim.
" />
        <ServiceV5 />
        <Footer />
    </div>
}

export default Service_V1


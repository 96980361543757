import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './shop-sidebar';

class ShopGridV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div>
			<div className="ltn__product-area ltn__product-gutter">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 order-lg-2 mb-100">
							
							<div className="tab-content">
							<div className="tab-pane fade active show" id="liton_product_grid">
								<div className="ltn__product-tab-content-inner ltn__product-grid-view">
								<div className="row">
								
									{/* ltn__product-item */}
									<div className="col-xl-6 col-sm-6 col-12">
									<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img go-top">
										<Link to="/product-details2"><img src={publicUrl+"assets/img/others/22.jpg"} alt="#" /></Link>
										
										</div>
										<div className="product-info">
										<div className="product-badge">
											<ul>
											<li className="sale-badg">Aircraft For Sale, Bell, Helicopters</li>
											</ul>
										</div>
										<h2 className="product-title go-top"><Link to="/product-details">2022 Bell 429</Link></h2>
										
										</div>
										<div className="product-info-bottom">
										<div className="product-price">
										<Link to="/product-details2">	<span>Devamını Oku</span></Link>
										</div>
										</div>
									</div>
									</div>
									{/* ltn__product-item */}
								{/* ltn__product-item */}
								<div className="col-xl-6 col-sm-6 col-12">
									<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img go-top">
										<Link to="/product-details3"><img src={publicUrl+"assets/img/helicopters/5.jpg"} alt="#" /></Link>
										
										</div>
										<div className="product-info">
										<div className="product-badge">
											<ul>
											<li className="sale-badg">Aircraft For Sale, Eurocopter, Helicopters</li>
											</ul>
										</div>
										<h2 className="product-title go-top"><Link to="/product-details">2007 Eurocopter AS350B2</Link></h2>
										
										</div>
										<div className="product-info-bottom">
										<div className="product-price">
										<Link to="/product-details3">	<span>Devamını Oku</span></Link>
										</div>
										</div>
									</div>
									</div>
									</div>

								</div>
							</div>
						
							</div>
						
						</div>
						<Sidebar />
					</div>
				</div>
			</div>
						
		
			
		
			</div>

        }
}

export default ShopGridV1
import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import HelicoptersPage1 from "./shop-components/helicopters"

const HelicoptersPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Helikopterler" />
        <HelicoptersPage1 />
        <Footer />
    </div>
}

export default HelicoptersPage


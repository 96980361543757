import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomeV1 from './components/home-v1';
import About from './components/about';
import Service from './components/service';
import Portfolio from './components/portfolio';
import Team from './components/team';
import Jets1 from '../src/components/jetspage';
import ProdductDetails from './components/product-details';
import Helicopters from '../src/components/helicopterspage';
import Turbo from '../src/components/turbopage';
import Pistons from './components/pistonspage';
import ProdductDetails1 from './components/product-details1';
import ProdductDetails2 from './components/product-details2';
import ProdductDetails3 from './components/product-details3';
import ProdductDetails4 from './components/product-details4';
import ProdductDetails5 from './components/product-details5';
import ProdductDetails6 from './components/product-details6';
import ProdductDetails7 from './components/product-details7';
import Contact from './components/contact';
import Cart from './components/cart';

class Root extends Component {
  render() {
    return (
      <Router basename="/">
        <div>
          <Switch>
            <Route exact path="/" component={HomeV1} />
            <Route path="/about" component={About} />
            <Route path="/service" component={Service} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/team" component={Team} />
            <Route path="/jets" component={Jets1} />
            <Route path="/helicopters" component={Helicopters} />
            <Route path="/turbo" component={Turbo} />
            <Route path="/pistons" component={Pistons} />
            <Route path="/product-details1" component={ProdductDetails1} />
            <Route path="/product-details2" component={ProdductDetails2} />
            <Route path="/product-details3" component={ProdductDetails3} />
            <Route path="/product-details4" component={ProdductDetails4} />
            <Route path="/product-details5" component={ProdductDetails5} />
            <Route path="/product-details6" component={ProdductDetails6} />
            <Route path="/product-details7" component={ProdductDetails7} />
            <Route path="/product-details" component={ProdductDetails} />
            <Route path="/contact" component={Contact} />
            <Route path="/cart" component={Cart} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));

import React, { Component } from 'react';

class Banner extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+''

    return    <div className="ltn__slider-area ltn__slider-3 ">
				  <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">				    
				                <img src={publicUrl+"/assets/img/team/10.jpg"} alt="#" />
						<img src={publicUrl+"/assets/img/team/9.jpg"} alt="#" />
						<img src="https://source.unsplash.com/white-airplane--FhoJYnw-cg" alt="#" />
					
				  </div>
			</div>
        }
}

export default Banner
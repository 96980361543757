import React, { Component } from 'react';

class ShopDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">


				<div className="row">
				
				<div className="col-lg-6 align-self-center ">
				<div className="about-us-info-wrap">
					<div className="  mb-20">
					<h1 className="section-title">
					2015 Gulfstream G650ER</h1>
					<h5 className="">Gulfstream, Jets</h5>
					<p>
					YEAR: 2015<br/>
MANUFACTURER: GULFSTREAM<br/>
MODEL: G650ER<br/>
TIME: 1,923 HOURS SINCE NEW<br/>
LOCATION: USA<br/>
SN: 6123<br/>
REGISTRATION: N281MM	</p>
					</div>
				
				</div>
				</div>
				<div className="col-lg-6 align-self-center pt-60">
				<div className="">
					<img src={publicUrl+"assets/img/jest/10.jpg"} alt="About Us Image" />
					
				</div>
				</div>
			</div>


				<div className="row">

					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">

						<h4 className="title-2">Galeri</h4>
						<div className="ltn__property-details-gallery mb-30">
						<div className="row">
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/jest/3.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/jest/7.jpg"} alt="Image" />
							</a>
							<a href={publicUrl+"assets/img/jest/2.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/jest/8.jpeg"} alt="Image" />
							</a>
							</div>
							<div className="col-md-6">
							<a href={publicUrl+"assets/img/jest/3.jpg"} data-rel="lightcase:myCollection">
								<img className="mb-30" src={publicUrl+"assets/img/jest/9.jpg"} alt="Image" />
							</a>
							</div>
						
						</div>
						</div>
					
					
						{/* APARTMENTS PLAN AREA START */}
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
							<div className="nav">
							<a data-bs-toggle="tab" href="#liton_tab_3_1">Detaylar</a>
							<a className="active show" data-bs-toggle="tab" href="#liton_tab_3_2">İçerik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_3" >Motor</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_4" >Aviyonik</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_5" >Ek Ekipman</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_6" >Dış iç mekan</a>



							</div>
						</div>
						<div className="tab-content">
							<div className="tab-pane fade" id="liton_tab_3_1">
							<div className="ltn__apartments-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Kayıtlı ve ABD merkezli. Yeni beri sadece iki özel sahip.<br/>
									MSP'de Rolls Royce Corporate Care (RRCC) ve APU'ya kayıtlı motorlar.<br/>
									Geliştirilmiş NAV (FANS-1/A+, WAAS/LPV, ADS-B V2, TCAS 7.1), SVS, EVS, HUD, RAAS, LSS, vb.<br/>
									Dört kabin bölgesinde ve FWD Mutfakta on yedi (17) Yolcu İç Mekan konfigürasyonu.<br/>
									KA Band Wi-Fi İnternet, CabinView Uçuş Bilgi Sistemi ve AirCell Axxess II Iridium Uçak İçi Telefon.<br/>
									Premium Boyama Gulfstream SAV tarafından 2022'de yapıldı.<br/>
									EJM tarafından yönetiliyor ve her zaman Gulfstream bakımı yapılıyor. GAC SAV tarafından yapılan taze yıllık.<br/>
									Her zaman özel bir hangarda. Hasar geçmişi yok.</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade active show" id="liton_tab_3_2">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Yeni sürümden bu yana 1.923 saat
</p>
									</div>
								</div>
							
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_3">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>
												Motor, Rolls Royce Corporate Care'e (RRCC) kayıtlı.<br/>

												Yeni sürümden bu yana 1.923 saat<br/>

										No Program.</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_4">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>Gulfstream PlaneViewTM II, Honeywell Primus'u temel alır Epic Aviyonik<br/>
									Honeywell DU-1310 Elektronik Uçuş Gösterge Sistemi (EFIS)<br/>
									Üçlü Honeywell Çok Fonksiyonlu Kontrol Ekranı Birimi (MCDU)<br/>
									Üçlü Honeywell LASEREF VI Ataletsel Referans Sistemi (IRS)<br/>
									Head-Up Display (HUD) II<br/>
									Kollsman Gelişmiş Görüş Sistemi (EVS) II<br/>
									Acil Durum Görüş Güvence Sistemi (EVAS)<br/>
									Üçlü Honeywell TR-865A Çok Yüksek Frekanslı Haberleşme (VHF COMM)<br/>
									Dual Collins HF-9034A Yüksek Frekanslı Haberleşme (HF COMM)<br/>
									Honeywell MCS-7120 Uydu Haberleşme (SATCOM)<br/>
									İkili Honeywell AH-1000 Durum Yönü Referans Birimi (AHRU)<br/>
									Çift Honeywell KRA-405B Radar Altimetre (Radar ALT)<br/>
									Çift Honeywell XS-858B Transponder (XPDR)<br/>
									Pist Farkındalık Tavsiye Sistemi (RAAS)<br/>
									Çift Honeywell Geliştirilmiş Yere Yakınlık Uyarı Sistemi (EGPWS)<br/>
									Trafik Uyarısı ve Çarpışma Kaçınma Sistemi II, Değişiklik 7.1 ile (TCAS II değişiklik 7.1)<br/>
									Evrensel CVR-120R Kokpit Ses Kayıt Cihazı (CVR)<br/>
									Evrensel FDR-25 Uçuş Veri Kaydedici (FDR)<br/>
									Artex C406-N Acil Durum Yer Belirleme Vericisi (ELT)<br/>
									Honeywell LP-860 Yıldırım Sensörü Sistem (LSS)<br/>
									Honeywell TR-1 Hava Durumu Radarı (WX Radar)<br/>
									Tahminli Rüzgar Kesme</p>
									</div>
								</div>
								
								</div>
							</div>
							</div>




							<div className="tab-pane fade" id="liton_tab_3_5">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>KA-Bant Yüksek Hızlı Wi-Fi İnternet Sistemi<br/>
									Sentetik Görüş Sistemi (SVS)<br/>
									Kollsman Geliştirilmiş Görüş Sistemi (EVS) II<br/>
									Head-Up Display (HUD) II<br/>
									Evrensel FDR-25 Uçuş Veri Kaydedici (FDR)<br/>
									Honeywell LP-860 Yıldırım Sensör Sistemi ( LSS)<br/>
									Pist Farkındalık Danışma Sistemi (RAAS)<br/>
									Acil Durum Görüş Güvence Sistemi (EVAS)<br/>
									Harici Kameralar<br/>
									AirCell Axxess II Iridium Uçak İçi Telefon<br/>
									CabinView Uçuş Bilgi Sistemi<br/>
									Çift Blu-Ray / DVD Disk Oynatıcılar<br/>
									Üç (3) Büyük Kabin Monitörü artı Kabinde Bireysel Monitörler Kulüp koltukları<br/>
									Premium Kabin Ses Sistemi</p>
									</div>
								</div>
								</div>
							</div>
							</div>


							
							<div className="tab-pane fade" id="liton_tab_3_6">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
							
								<div className="col-lg-8">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<p>İç Mekan: Ön Lüks Mutfak içeren Geniş Onyedi (17) Yolcu konfigürasyonu:<br/>
									FWD Kabin: Dörtlü Kulüp Koltuğu<br/>
									MID Kabin 1: Bir adet 3'lü Divan ve bir adet 2'li Tekli Kulüp Koltuğu<br/>
									MID Kabin 2: Karşı tarafta bir adet 4'lü Konferans Grubu Credenza<br/>
									AFT Kabinine: İki adet 3'lü Divan (Her biri kalkış ve iniş için sertifikalı iki yer)<br/>
									Lavabolar: FWD Mürettebat Tuvaleti ve Kıç Ana Lavabo<br/>
									Tasarımı: Elektrikli Açılır Masalı Kulüp Koltukları, Uzatmalı<br/>
									Lüks Mutfaklı Konferans Grup Masası Ekipman: Kahve ve Espresso Makinesi, Yüksek Sıcaklık Fırın, Mikrodalga, Buzdolabı, Buz Çekmecesi, Sofra Takımı/ Çini/ Kristal Depoları, Çöp Konteyneri, Dokunmatik Ekran Kabin Kontrol Paneli Eğlence<br/>
									ve Bağlantı: İki Büyük Bölme Monitörü, Bir Büyük Geri Çekilebilir Credenza Monitör, Bireysel Monitörler Kulüp Koltuklarında, Premium Ses Sistemi, CabinView Uçuş Bilgi Sistemi, Blu-Ray/DVD Disk Oynatıcılar, Harici Kameralar, Uydu Telefonu, KA-BAND Yüksek Hızlı Wi-Fi İnternet</p>
									</div>
								</div>
								</div>
							</div>
							</div>
							</div>
								</div>
							</div>
							</div>
			
				</div>
				</div>
			</div>
        }
}

export default ShopDetails